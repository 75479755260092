.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.white-bg {
  background-color: transparent;
}

.header-scrolled.white-bg {
  background-color: white !important;
  color: #35322d;
}
.header-scrolled.white-bg .scrollto {
  color: #35322d;
}
.header-scrolled.white-bg .dropdown span {
  color: #35322d;
}
.header-scrolled.white-bg .dropdown span:hover {
  color: #ffb03b;
}
.header-scrolled.white-bg .scrollto:hover {
  color: #ffb03b;
}
.header-scrolled.white-bg .home {
  color: #ffb03b;
}

/*-------------------------- round image ------------------------- */

.image--cover {
  width: 195px;
  height: 170px;
  border-radius: 50%;
  margin: 20px;

  object-fit: cover;
  object-position: center right;
}

.product-images-description {
  text-align: center;

  margin-top: 20px;
  color: #35322d;
}

.product-images-description h1 {
  font-size: 50px;
  font-weight: 500;
  line-height: 15vh;
  padding-top: 45px;
}

.link li {
  color: azure;
}
/*------------------------- banner2------------------------- */
.banner2 {
  position: relative;
  background-color: #000;
}

.banner2::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.5;
  background: url("../public/assets/img/Banner2.avif") no-repeat center center;
  background-size: cover;
}

.banner2 .text {
  position: relative;
  width: 100%;
  height: 500px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  text-align: center;
}
/* .banner2 {
  position: relative;
  text-align: end;
  color: white;
} */

/* .banner2 img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}
.banner2 .text {
  position: absolute;
  top: 50%;
  left: 34%;
  text-align: left;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
} */

.Banner3 {
  background-color: beige;
  width: 100%;
}

.product-images {
  text-align: center;
  align-items: center;
  justify-content: center;
}
.product-images-main {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #efca93;
}
.product-images-main .product-images-description h1 {
  width: 100%;
  text-align: center;
  line-height: 40px;
  font-size: 42px;
  font-family: "Segoe UI";
  font-style: italic;
}
.product-images-main .product-images-description h3 {
  padding-top: 10px;
  width: 100%;
  text-align: center;
  line-height: 30px;
  font-size: 22px;
  font-family: sans-serif;
}
.image--cover1 {
  width: 350px;
  height: 300px;
  border-radius: 50%;
  margin: 20px;

  object-fit: cover;
  object-position: center right;
}

.banner-4 img {
  margin-top: 23px;
}

/*---------------- bx-check-double----------------- */
.bx-check-double {
  color: #ffb03b;
}

/*---------------- breads-headere----------------- */
.breads-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: auto;
  margin-top: 70px;
}
.breads-header-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
}
.breads-header-right h1 {
  font-size: 35px;
}

.breads-header-right p {
  width: 80%;
  text-align: justify;
}
.breads-header-image {
  height: 100px;
}
.Artisan-Breads {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.Artisan-Breads-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Artisan-Breads-image img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin-right: 20px;
}
.breads-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}
.breads-card-main {
  text-align: center;
  padding: 20px 0px;
}
.breads-card-main h1 {
  text-align: center;
  padding: 20px 0px;
}

.breads-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  margin: 1rem;
  transition: transform 0.2s;
}

.breads-card:hover {
  transform: scale(1.05);
}

.breads-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.breads-card-content {
  padding: 1rem;
}

.breads-card-content h2 {
  margin-top: 0;
  font-size: 1.5rem;
}

.breads-card-content p {
  color: #555;
}
.breads-card-main1 {
  display: grid;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  background-color: #35322d;
  padding: 20px 0px;
  text-align: center;
}
.breads-card-main1 h1 {
  color: aliceblue;
}
.home-breads-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 80px;
  background-color: #fff;
}
.home-breads-card-content {
  padding: 1rem;
  height: 220px;
}
.tab-content .fst-italic {
  margin-top: 150px;
}
.w-home {
  width: 100%;
}

.gallery {
  width: 100%;
  box-sizing: border-box;
  padding: 0 2.5%;
}
.gallery-item {
  box-sizing: border-box;
  padding: 0 2.5%;
}
.gallery img {
  width: 100%;
  height: 283px;
  object-fit: cover;
}
.tab-pane img {
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  height: 250px;
}

/* SpinnerSquare.css */
.spinner-square {
  display: flex;
  flex-direction: row;
  width: 90px;
  height: 120px;
}

.spinner-square > .square {
  width: 17px;
  height: 80px;
  margin: auto auto;
  border-radius: 4px;
}

.square-1 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s
    infinite;
}

.square-2 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 200ms
    infinite;
}

.square-3 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 400ms
    infinite;
}

@keyframes square-anim {
  0% {
    height: 80px;
    background-color: #ffb03b;
  }
  20% {
    height: 80px;
  }
  40% {
    height: 120px;
    background-color: #ce800b;
  }
  80% {
    height: 80px;
  }
  100% {
    height: 80px;
    background-color: #ffb03b;
  }
}

.spinermain {
  width: 100%;
  height: 120vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 786px) {
  .breads-header-right h1 {
    font-size: 17px;
    text-align: center;
    line-height: 25px;
  }
  .banner2 .text {
    position: relative;
    width: 100%;
    height: 400px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    text-align: center;
  }
  .mobile-nav-toggle {
    color: #fa9c10;
  }
  .product-images-main .product-images-description h1 {
    width: 100%;
    text-align: center;
    line-height: 40px;
    font-size: 42px;
    font-family: "Segoe UI";
    font-style: italic;
  }

  @media (max-width: 786px) {
    .product-images-main .product-images-description h1 {
      width: 100%;
      text-align: center;
      line-height: 40px;
      font-family: "Segoe UI";
      font-style: italic;
      font-size: 24px;
    }
  }
  .product-images-main .product-images-description h3 {
    width: 100%;
    text-align: center;
    line-height: 30px;
    font-size: 22px;
    font-family: sans-serif;
  }
  .tab-content .fst-italic {
    margin-top: 0px;
  }
  .home-breads-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .image--cover {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin: 10px 56px;

    object-fit: cover;
    object-position: center right;
  }

  .banner-4 img {
    margin-top: 0px;
  }

  /*---------------- breads-headere----------------- */
  .breads-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
  }
  .breads-header h1 {
    width: 90%;
    margin-top: 30px;
  }
  .breads-header-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
  }

  .breads-header-right p {
    width: 80%;
    text-align: justify;
  }
  .breads-header-image {
    height: 100px;
  }
  .Artisan-Breads {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }
  .Artisan-Breads-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .Artisan-Breads-image img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    margin-right: 20px;
  }
}

@media (max-width: 768px) {
  .gallery {
    width: 93.5%;
    box-sizing: border-box;
    padding: 0 2.5%;
    /* margin-right: 20px; */
    margin-left: 14px;
  }
  .gallery-item {
    padding: 0;
  }
  .gallery img {
    height: auto;
  }
}
